import React from 'react'
import styles from './space_box_featured.module.scss'
import Card from '@material-ui/core/Card';


const SpaceBoxFeatured = ({image, title, price, capacity, meters, sede}) => {
	return(
		<Card className={styles.cardContainer}>
			<div className={styles.container}>
				<div className={styles.backImage}>
					<img alt="imagen-espacio" src={image} />
					<div className={styles.sedeOffice}>{sede}</div>
				</div>
				<div className={styles.containerData}>
					<h3> {title} </h3>
					<div className={styles.data}>
						<div className={styles.minData}>
							<p className={styles.subData}> Desde </p>
							<p className={styles.realData}> {price} </p>
						</div>
						<div className={styles.minData}>
							<p className={styles.subData}> Personas </p>
							<p className={styles.realData}> {capacity} </p>
						</div>
						<div className={styles.minData}>
							<p className={styles.subData}> M2 </p>
							<p className={styles.realData}> {meters} </p>
						</div>
					</div>
				</div>
			</div>
		</Card>
	)
}

export default SpaceBoxFeatured
