import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React from 'react'

const mapStyles = {
  width: '100%',
  height: '100%',
  borderRadius: '0.8em'
};

const MapView = ({google, latitude, longitude}) => {
	return(
		<Map
			style={mapStyles}
			google={google}
			zoom={15}
			initialCenter={{ lat: latitude, lng: longitude}}
		>
			<Marker position={{ lat: latitude, lng: longitude}} />
		</Map>
	)
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAM_Vnd5sRbUqGLTLhxvwJ2wAffX5K7kVo')
})(MapView)
