import React, { useState } from 'react';
import styles from './carousel.module.scss'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import './styles.css'
import config from './../../config'

const CarouselOffice = ({ image }) => {
	const handleOnDragStart = e => e.preventDefault()
	let TestMaker = (image) => {
		//let img = require(`./../../images/${image.image_uid}`)
		//console.log(image)
		return (
			<div onDragStart={handleOnDragStart} className={styles.testimonioContainer}>
				<img src={`${config.assets}/${image.image_uid}`}/>
			</div>
		)
	}

	const [tests, setTests] = useState(image.map((el) => ( TestMaker(el))))
	const responsive = {
	  0: { items: 1 },
	  800: { items : 2 },
	  1024: { items: 3 },
	}

	//document.querySelectorAll('.alice-carousel__wrapper')[0].style.height = '400px'



	const handleColor = (e) => {
		/*console.log(window.innerWidth)
		if(window.innerWidth < 800){
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].style.background = "#DE892A";
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].children[1].children[0].style.color = "#FFF";
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].children[1].children[1].style.color = "#FFF";
		}else{
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].style.background = "#DE892A";
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].children[1].children[0].style.color = "#FFF";
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].children[1].children[1].style.color = "#FFF";
		}*/

	}

	const removeColor = (e) => {
		/*if(window.innerWidth < 800){
			console.log("entro")
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].style.background = "#FFF";
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].children[1].children[0].style.color = "#7F8299";
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].children[1].children[1].style.color = "#23263E";
		}else{
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].style.background = "#FFF";
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].children[1].children[0].style.color = "#7F8299";
			document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].children[1].children[1].style.color = "#23263E";
		}*/
	}

	const initColor = (e) => {
		try{
			setTimeout(function(){
				console.log(document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[1])
				//document.querySelectorAll('.alice-carousel__wrapper')[0].style.height = '400px'
				/*if(window.innerWidth < 800){
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[0].children[0].style.background = "#DE892A"
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[0].children[0].children[1].children[0].style.color = "#FFF"
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[0].children[0].children[1].children[1].style.color = "#FFF"
				}else{
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[1].children[0].style.background = "#DE892A"
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[1].children[0].children[1].children[0].style.color = "#FFF"
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[1].children[0].children[1].children[1].style.color = "#FFF"
				}autoPlay={true} */

			}, 1000)
		}catch(e){

		}

	}

	const resized = (e) => {

	}



	return (
		<div className={styles.container} id="carouselContainer">
			<AliceCarousel onResized={resized} dotsDisabled buttonsDisabled autoPlay duration={1500} autoPlayInterval={500} mouseDragEnabled responsive={responsive} items={tests} onInitialized={initColor} onSlideChanged={handleColor} onSlideChange={removeColor}>
			</AliceCarousel>
		</div>
	)
}

export default CarouselOffice;
