import React, { useState, useEffect, useRef, Fragment } from 'react'
import { navigate } from 'gatsby'
import styles from './office.module.scss'
import Layout from './../../components/Layout'
import { makeStyles } from '@material-ui/core/styles';
import CarouselOffice from './../../components/CarouselOffice'
import SpaceBoxFeatured from './../../components/SpaceBoxFeatured'
import MapView from './../../components/MapView'
import {
	Button, Snackbar, SnackbarContent, Dialog, DialogTitle,
	DialogContent, List, ListItem, ListItemText, Avatar, ListItemAvatar, TextField, MenuItem,
	CardContent, Card
} from '@material-ui/core'
import { FaCheckCircle, FaCalendarDay, FaCalendarWeek, FaCalendar } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux'
import "./video-react.css";
import { Player } from 'video-react';
import SEO from './../../components/seo'
import { DiscussionEmbed } from 'disqus-react'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { createMuiTheme } from '@material-ui/core/styles'
import Lottie from 'react-lottie';
import { ThemeProvider } from "@material-ui/styles";
import config from './../../config'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import axios from 'axios'
import CryptoJS from 'crypto-js'
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InboxIcon from '@material-ui/icons/Inbox';
import moment from 'moment'
import es from "date-fns/locale/es";
import { fontStyle, fontSize } from '@material-ui/system';
import NumberFormat from 'react-number-format';
import Moment from 'moment';


Date.prototype.addDays = function (days) {
	var date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
}

function getDates(startDate, stopDate) {
	var dateArray = new Array();
	var currentDate = startDate;
	while (currentDate <= stopDate) {
		dateArray.push(new Date(currentDate));
		currentDate = currentDate.addDays(1);
	}
	return dateArray;
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const useStyles = makeStyles({

	dialogPaper: {
		margin: 0
	}

});

const Office = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch()
	const isLogged = useSelector(state => state.user.isLogged)
	const isConfirm = useSelector(state => state.user.isConfirm)
	const userData = useSelector(state => state.user.userData)
	const [openSnack, setOpenSnack] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const [resumenCompra, setResumenCompra] = useState('')
	const [promoCode, setPromoCode] = useState('')
	const [openDialogCalendar, setOpenDialogCalendar] = useState(false)
	const [openDialogResumen, setOpenDialogResumen] = useState(false)
	const [payMethod, setPayMethod] = useState("Monthly");
	const [officeData, setOfficeData] = useState()
	const [openDialogPromo, setOpenDialogPromo] = useState(false)
	const [officeImages, setOfficeImages] = useState()
	const [nameData, setNameData] = useState('')
	const [dataDisableDays, setDataDisableDays] = useState('')
	const [errorMessage, setErrorMessage] = useState({
		'message': '',
		'color': ''
	})
	const [disabledDates, setDisabledDates] = useState()
	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: 'selection',
	})
	const [dateRangeDisabled, setDateRangeDisableb] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: 'selection',
	})
	const mapRef = useRef(null)
	const executeScroll = () => scrollToRef(mapRef)
	const disqusConfig = {
		shortname: process.env.GATSBY_DISQUS_NAME,
		config: { identifier: props.pageContext.title },
	}
	const [cantSelect, setCantSelect] = useState("1")
	const [values, setValues] = useState({
		Monthly: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
		Weekly: ["1", "2", "3"],
		Daily: ["1", "2", "3", "4", "5", "6"]
	}
	);

	const [extraPayU, setExtraPayU] = useState({
		startDate: "2020-01-15",
		endDate: "2020-01-30",
		office_id: 5,
		user_id: 1,
		promo_code: "cad56ef1552f",
		amount: 50000,
		amount_with_discount: 42000
	})
	

	function handleChange(event) {
		setCantSelect(parseInt(event.target.value))
	}

	const handleCalendarChange = (ranges) => {
		if (payMethod === "Monthly") {
			let count = 0;
			let semana = 29;

			let sumaDias = semana * cantSelect;

			ranges.selection.endDate = (ranges.selection.startDate).addDays(sumaDias)

			const dateFormat = 'YYYY-MM-DD';
			const diff = moment(ranges.selection.endDate).diff(ranges.selection.startDate, 'days') + 1;

			var daysDis = [];

			for (let a = 0; a < dataDisableDays.ranges.length; a++) {
				daysDis.push(moment(dataDisableDays.ranges[a]).format(dateFormat));
			}

			for (let i = 0; i < diff; i++) {
				const checkDate = moment(ranges.selection.startDate).add(i, 'd').format(dateFormat);

				if (daysDis.indexOf(checkDate) !== -1) {
					count++;
				}
			}

			let addMonthly = (count + cantSelect) - 1
			/* se asigna valor a rangeselectionDate */
			if (count) {
				setErrorMessage({ 'message': 'Debes seleccionar un rango distinto, hay dias  deshabilitados.', 'color': '#d32f2f' })
				setTimeout(
					function () {
						setOpenSnack(true);
					}
						.bind(this),
					500
				);
			} else {
				ranges.selection.endDate = (ranges.selection.startDate).addDays((sumaDias) + addMonthly)

				setDateRange(ranges.selection)
			}

		} else if (payMethod === "Weekly") {

			let count = 0;
			let semana = 6;

			let sumaDias = semana * cantSelect;

			ranges.selection.endDate = (ranges.selection.startDate).addDays(sumaDias)

			const dateFormat = 'YYYY-MM-DD';
			const diff = moment(ranges.selection.endDate).diff(ranges.selection.startDate, 'days') + 1;



			var daysDis = [];

			for (let a = 0; a < dataDisableDays.ranges.length; a++) {
				daysDis.push(moment(dataDisableDays.ranges[a]).format(dateFormat));
			}

			for (let i = 0; i < diff; i++) {
				const checkDate = moment(ranges.selection.startDate).add(i, 'd').format(dateFormat);

				if (daysDis.indexOf(checkDate) !== -1) {
					count++;
				}

			}

			var addWeekly = (count + cantSelect) - 1

			if (count) {
				setErrorMessage({ 'message': 'Debes seleccionar un rango distinto, hay dias  deshabilitados.', 'color': '#d32f2f' })
				setTimeout(
					function () {
						setOpenSnack(true);
					}
						.bind(this),
					500
				);
			} else {

				/* se asigna valor a rangeselectionDate */

				ranges.selection.endDate = (ranges.selection.startDate).addDays((sumaDias) + addWeekly)

				setDateRange(ranges.selection)

			}



		} else if (payMethod === "Daily") {
			let count = 0;
			let semana = 1;

			let sumaDias = semana * cantSelect;

			ranges.selection.endDate = (ranges.selection.startDate).addDays(sumaDias)

			const dateFormat = 'YYYY-MM-DD';
			const diff = moment(ranges.selection.endDate).diff(ranges.selection.startDate, 'days');


			var daysDis = [];

			for (let a = 0; a < dataDisableDays.ranges.length; a++) {
				daysDis.push(moment(dataDisableDays.ranges[a]).format(dateFormat));
			}

			for (let i = 0; i < diff; i++) {
				const checkDate = moment(ranges.selection.startDate).add(i, 'd').format(dateFormat);

				if (daysDis.indexOf(checkDate) !== -1) {
					count++;
				}

			}

			if (count) {
				setErrorMessage({ 'message': 'Debes seleccionar un rango distinto, hay dias  deshabilitados.', 'color': '#d32f2f' })
				setTimeout(
					function () {
						setOpenSnack(true);
					}
						.bind(this),
					500
				);
			}
			else {
				/* se asigna valor a rangeselectionDate */

				ranges.selection.endDate = (ranges.selection.startDate).addDays((sumaDias) + count - 1)

				setDateRange(ranges.selection)
			}
		}

	}

	const handleCloseDialog = () => {
		setOpenDialog(!openDialog)
	}

	const handleCloseDialogCalendar = () => {
		setOpenDialogCalendar(!openDialogCalendar)
		setDateRange({
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		})
	}

	const handleCloseDialogPromo = () => {
		setOpenDialogPromo(!openDialogPromo)
	}

	const handleCloseDialogResumen = () => {
		setOpenDialogResumen(!openDialogResumen)
		setOpenDialogCalendar(!openDialogCalendar)
		setOpenDialogPromo(false)
	}


	const SnackBarWrapper = ({ message }) => {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={() => setOpenSnack(!openSnack)}
			>
				<SnackbarContent
					style={{ backgroundColor: errorMessage.color }}
					message={
						<span className={styles.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={styles.iconError} />
								) : (
										errorMessage.color == "#43a047" ? (
											<IoIosCheckmarkCircle size={20} className={styles.iconError} />
										) : (
												<p>Nada</p>
											)
									)
							}
							<span>{message}</span>
						</span>
					}
					onClose={() => setOpenSnack(!openSnack)}
					variant="error"
				/>
			</Snackbar>
		)
	}

	const getCalendarBookings = async () => {
		try {
			const response = await fetch(`${config.server}/getBookingsOffice?office_id=${props.pageContext.id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				},
			})
			const fetchData = await response.json()
			let disabledDatesArr = []
			setDisabledDates([])
			fetchData.map((el, idx) => {
				disabledDatesArr = [...disabledDatesArr, ...getDates(new Date(el.from_date.replace(/-/g, ',')), new Date(el.to_date.replace(/-/g, ',')))]
				if (idx == fetchData.length - 1) {
					setDisabledDates(disabledDatesArr)
				}
			})
		} catch (e) {
			console.log(e)
		}
	}

	const handleButtonBooking = () => {
		if (!isLogged) {
			navigate('/login')
		} else if (!isConfirm) {
			setErrorMessage({ 'message': 'Para reservar debes confirmar tu correo', 'color': '#d32f2f' })
			setOpenSnack(true)
		} else {
			setOpenDialog(true)
			getDisableDays()
			
		}
	}

	const handleBookingCheck = () => {
		if (payMethod === "Monthly") {
			const Difference_In_Time = dateRange.endDate.getTime() - dateRange.startDate.getTime();
			const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			if ((Difference_In_Days + 1) < 30) {
				setErrorMessage({ 'message': 'Debes seleccionar la cantidad de meses.', 'color': '#d32f2f' })
				setOpenSnack(true)
			} else {
				//handleBooking()
				//submitPayU()
				handleCloseDialogPromo()
				setOpenDialogCalendar()
				getCalendarBookings()
			}
		} else if (payMethod === "Weekly") {
			const Difference_In_Time = dateRange.endDate.getTime() - dateRange.startDate.getTime();
			const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			if ((Difference_In_Days + 1) < 7) {
				setErrorMessage({ 'message': 'Debes seleccionar la cantidad de semanas.', 'color': '#d32f2f' })
				setOpenSnack(true)
			} else {
				//handleBooking()
				//submitPayU()
				handleCloseDialogPromo()
				setOpenDialogCalendar()
				getCalendarBookings()
			}
		} else {
			//handleBooking()
			//submitPayU()
			handleCloseDialogPromo()
			setOpenDialogCalendar()
			getCalendarBookings()
		}
	}

	const handleContinue = async () => {
		if (/^\s*$/.test(promoCode)) {
			submitPayUModal()
			handleCloseDialogCalendar()
			handleCloseDialogPromo()
			handleCloseDialogResumen()
			setDateRange({
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			})
		} else {
			try {
				const response = await axios({
					url: `${config.server}/checkCodeUser`,
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					data: {
						user_id: userData.id,
						code: promoCode.toLowerCase()
					}
				})
				const promosCodes = response

				if (promosCodes.data.message == "DOESNT_EXIST") {
					setErrorMessage({ 'message': 'El codigo de promocion es invalido.', 'color': '#d32f2f' })
					setOpenSnack(true)
				} else if (promosCodes.data.message == "ALREADY_USED") {
					setErrorMessage({ 'message': 'Ya has usado este codigo de promocion.', 'color': '#d32f2f' })
					setOpenSnack(true)
				} else if (promosCodes.data.message == "SUCCESS") {
					if (new Date(promosCodes.data.data.expired_date) > new Date()) {
						getPromosCode()
						submitPayUModal(promosCodes.data.data.percent)
						handleCloseDialogCalendar()
						handleCloseDialogPromo()
						handleCloseDialogResumen()
						setDateRange({
							startDate: new Date(),
							endDate: new Date(),
							key: 'selection',
						})
					} else {
						setErrorMessage({ 'message': 'Este codigo ya expiro.', 'color': '#d32f2f' })
						setOpenSnack(true)
					}
				}
			} catch (e) {
				console.log(e)
			}
		}
	}

	const codeRef = () => {
		let referenceCode = Math.random().toString(36).substring(7);
		document.getElementById('referenceCodeFormPayU').value = referenceCode
	}

	const getPromosCode = async () => {
		try {
			const response = await fetch(`${config.server}/getCodesByUser/${userData.id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const promosCodes = await response.json();
			dispatch({ type: 'user/SET_PROMOS_CODE', data: promosCodes })
		} catch (e) {
			console.log(e)
		}
	}

	const getDisableDays = async () => {
		try {
			const response = await fetch(`${config.server}/checkAvailability/${officeData.id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json();
			setDataDisableDays(data)
		} catch (e) {
			console.log(e)
		}
	}

	console.log("dataDisableDays", officeData)

	const submitPayUModal = (discount) => {
		document.getElementById('emailDataFormPayU').value = userData.email
		let dataToSend;
		dataToSend = {
			user_id: userData.id,
			office_id: props.pageContext.id,
			startDate: dateRange.startDate,
			endDate: dateRange.endDate,
			promo_code: promoCode
		}
		
		if (discount) {
			dataToSend = {
				user_id: userData.id,
				office_id: props.pageContext.id,
				startDate: dateRange.startDate,
				endDate: dateRange.endDate,
				promo_code: promoCode
			}
		} else {
			dataToSend = {
				user_id: userData.id,
				office_id: props.pageContext.id,
				startDate: dateRange.startDate,
				endDate: dateRange.endDate,
				promo_code: 'doesnt'
			}
		}
		setPromoCode('')
		document.getElementById('phoneDataFormPayU').value = userData.phone
		document.getElementById('referenceCodeFormPayU').value = CryptoJS.MD5(`${Date.now()}-${userData.email}`)
		
		const Difference_In_Time = dateRange.endDate.getTime() - dateRange.startDate.getTime();
		const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
		if (payMethod == "Monthly") {
			document.getElementById('amountDataFormPayU').value = officeData.monthly * ((Difference_In_Days + 1) / 30)
		} else if (payMethod == "Weekly") {
			document.getElementById('amountDataFormPayU').value = officeData.weekly * ((Difference_In_Days + 1) / 7)
		} else if (payMethod == "Daily") {
			document.getElementById('amountDataFormPayU').value = officeData.daily * (Difference_In_Days + 1)
		}

		let tempAmount = document.getElementById('amountDataFormPayU').value
		if (discount) {
			var amount_with_discount = tempAmount - tempAmount * (discount / 100)
			document.getElementById('amountDataFormPayU').value = amount_with_discount
		}
		
		var resumenCompra = {
			monto: document.getElementById('amountDataFormPayU').value,
			email: userData.email,
			telefono: document.getElementById('phoneDataFormPayU').value,			
			startDate: dateRange.startDate,
			endDate: dateRange.endDate,
			promo_code: promoCode || "none",
			amount: tempAmount,
			amount_with_discount: amount_with_discount,
			user_id: userData.id,
			office_id: props.pageContext.id,
		}
		var name = {
			name:userData.name,
			lastname: userData.lastname,
			email: userData.email
		}
		setResumenCompra(resumenCompra)
		setNameData(name)
	}

	/*Se  envia a PayU*/
	const bottonEnv = () => {
		codeRef()
		handleCloseDialogCalendar()
		handleCloseDialogPromo()
		handleCloseDialogResumen()
		
		console.log("URL FIRST =",config.serverPayURegister)
		document.getElementById('amountDataFormPayU').value = resumenCompra.monto
		document.getElementById('emailDataFormPayU').value = resumenCompra.email
		document.getElementById('extraDataFormPayU2').value = JSON.stringify(nameData)
		document.getElementById('phoneDataFormPayU').value = resumenCompra.telefono		
		document.getElementById('payUForm').value = config.url_payu
		document.getElementById('confirmationUrl').value = config.serverPayURegister
		document.getElementById('responseUrl').value = config.serverResponse
		document.getElementById('accountId').value = config.accountId_payu
		document.getElementById('testPayu').value = config.test_payu	
		document.getElementById('merchantId').value = config.merchanId_payu
		document.getElementById('extraDataFormPayU').value = JSON.stringify(resumenCompra)
		document.getElementById('signatureFormPayU').value = CryptoJS.MD5(`${config.apiKey_payu}~${config.merchanId_payu}~${document.getElementById("referenceCodeFormPayU").value}~${document.getElementById("amountDataFormPayU").value}~COP`);

		document.getElementById('payUForm').submit()
		console.log("URL =", document.getElementById('confirmationUrl').value)
	}

	const getOfficeData = async () => {
		try {
			const response = await axios(`${config.server}/getOffices/${props.pageContext.id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			setOfficeData(response.data)
		} catch (e) {
			console.log(e)
		}
	}
	const getImagesById = async () => {
		try {
			const response = await axios(`${config.server}/images_by_id/${props.pageContext.id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			setOfficeImages(response.data)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getCalendarBookings()
		getOfficeData()
		getImagesById()
	}, [])

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: require('./../../animations/3678-fluid-loading-animation.json'),
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};








	const DialogWrapper = () => {
		return (
			<Dialog onClose={handleCloseDialog} open={openDialog}>
				<DialogTitle style={{ paddingBottom: 0 }}>¿Cómo quieres reservar?</DialogTitle>
				<DialogContent>
					<List>
						{(officeData.daily > 0) ? (
							<ListItem button onClick={() => {
								setPayMethod('Daily')
								setOpenDialogCalendar(true)
								setOpenDialog(false)
							}}>
								<ListItemAvatar>
									<Avatar>
										<FaCalendarDay />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Por día" />
							</ListItem>
						) : (
								null
							)}
						{(officeData.weekly > 0) ? (
							<ListItem button onClick={() => {
								setPayMethod('Weekly')
								setOpenDialogCalendar(true)
								setOpenDialog(false)
							}}>
								<ListItemAvatar>
									<Avatar>
										<FaCalendarWeek />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Por semana" />
							</ListItem>
						) : (
								null
							)}
							{(officeData.monthly > 0) ? (
							<ListItem button onClick={() => {
								setPayMethod('Monthly')
								setOpenDialogCalendar(true)
								setOpenDialog(false)
							}}>
								<ListItemAvatar>
									<Avatar>
										<FaCalendar />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Por mes" />
							</ListItem>
						) : (
								null
							)}
						
					</List>
				</DialogContent>
			</Dialog>
		)
	}


	var fromDay = "2020-06-01"
	var sumDay = 10
	var ddd = new Date(fromDay).addDays(sumDay)


	
	console.log("dayyyyyy", ddd)

	var disableDays = [((new Date()).addDays(10)), ((new Date()).addDays(11)), ((new Date()).addDays(14)), ((new Date()).addDays(50))]

	return (
		<div>
			{
				typeof disabledDates !== 'undefined' && typeof officeData !== 'undefined' ? (
					<Layout>
						<SEO title={`${officeData.name} - ${officeData.head_quarters_name}`} />
						{
							typeof officeImages == 'object' && officeImages.length > 0 ? (
								<CarouselOffice image={officeImages} />
							) : (
									null
								)
						}
						<div className={styles.container}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={9}>
									<div className={styles.leftContainer}>
										<div className={styles.mainData}>
											<h2>{officeData.name}</h2>
											<div className={styles.subDataContainer}>
												<div className={styles.sede}>{officeData.head_quarters_name}</div>
												<p className={styles.refLinkMap} onClick={executeScroll}>Ver en el mapa</p>
											</div>
											<div className={styles.detailContainer}>

												<div className={styles.subDetailContainer}>
													<img alt="regla" src={require("./../../images/ruler.svg")} />
													<h3>{officeData.size} m²</h3>
													<p>Tamaño</p>
												</div>
												<div className={styles.subDetailContainer}>
													<img alt="manos" src={require("./../../images/shake.svg")} />
													<h3>{officeData.capacity}</h3>
													<p>Personas</p>
												</div>
												<div className={styles.subDetailContainer}>
													<img alt="cubo" src={require("./../../images/cube.svg")} />
													<h3>{officeData.office_type_name}</h3>
													<p>Tipo de espacio</p>
												</div>
											</div>
										</div>
										<div className={styles.descriptionBox}>
											<h2>Descripción</h2>
											<p>{officeData.description}</p>
										</div>
										<div className={styles.beneficiosBox}>
											<h2>Beneficios</h2>
											<div className={styles.checkContainer}>
												{
													officeData.benefits.map((el, idx) => (
														<div className={styles.subCheckContainer}>
															{/* <FaCheckCircle/> */}
															<CheckCircleIcon />
															<p>{el}</p>
														</div>
													))
												}
											</div>
										</div>

										<div className={styles.mapBox}>
											<h2>Dirección</h2>
											<p ref={mapRef}>{officeData.address.add}</p>
											<div className={styles.mapContainer}>
												<MapView latitude={officeData.address.lat} longitude={officeData.address.lon} />
											</div>
										</div>
										{/* <div className={styles.commentsBox}>
											<DiscussionEmbed {...disqusConfig} />
										</div> */}
									</div>
								</Grid>
								<Grid item xs={12} md={3} lg={3} >
									<div className={styles.rightContainer}>
										<div className={styles.priceBox}>
											<p style={{ marginBottom: '.2em' }}>$ {officeData.daily.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}<span>/Diario</span></p>
											<p style={{ marginBottom: '.2em' }}>$ {officeData.weekly.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}<span>/Semanal</span></p>
											<p>$ {officeData.monthly.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}<span>/Mensual</span></p>

											<div className={styles.buttonBox}>
												<Button onClick={handleButtonBooking} variant="contained" className={styles.buttonBooking}>
													Reserva tu espacio
												</Button>
											</div>
											<form target="_blank" method="post" id="payUForm" action={config.url_payu}>
												<input name="merchantId" id="merchantId" type="hidden" value="833959" />
												<input name="accountId" id="accountId" type="hidden" value="841314" />
												<input name="description" type="hidden" value="Test PayU" />
												<input name="referenceCode" id="referenceCodeFormPayU" type="hidden" value="CoworkET" />
												<input name="amount" id="amountDataFormPayU" type="hidden" value="9999999" />
												<input name="tax" type="hidden" value="0" />
												<input id="extraDataFormPayU" name="extra1" type="hidden" value="2" />
												<input id="extraDataFormPayU2" name="extra2" type="hidden" value="2" />
												<input name="currency" type="hidden" value="COP" />
												<input name="signature" id="signatureFormPayU" type="hidden" value="2e905d5f64c8b4f18e3809b0630c2c01" />
												<input name="test" id="testPayu" type="hidden" value="1" />
												<input id="phoneDataFormPayU" name="telephone" type="hidden" value="test@test.com" />
												<input name="buyerEmail" id="emailDataFormPayU" type="hidden" value="test@test.com" />
												<input name="responseUrl"  id="responseUrl"  type="hidden"  value="http://localhost:8000/responsePayU" ></input>
												<input name="confirmationUrl" id="confirmationUrl" type="hidden" value="https://apicw.desarrollodeprueba.com:6054/payURequest" />
											</form>											
										</div>
										<div className={styles.contactBox}>
											<h3>Contactar Oficina</h3>
											<hr/>
											{/*<div className={styles.contactData}>
												<img alt="perfil_imagen" src={require('./../../images/robert.png')}/>
												<p>Roberto Daza</p>
											</div>*/}
											<Button variant="outlined" className={styles.buttonContact} onClick={()=>navigate('/contact')}>
												Contactar
											</Button>
										</div>
									</div>
								</Grid>
								</Grid>
						</div>

							<SnackBarWrapper message={errorMessage.message} />
							<DialogWrapper />
							<Dialog
								disableEscapeKeyDown onClose={handleCloseDialogPromo}
								open={openDialogPromo}
								PaperProps={{
									style: {
										margin: '0.5em',
									},
								}}
							>
								<DialogTitle style={{ textAlign: 'center', marginTop: "30px" }}>¿Tienes un código de promoción?</DialogTitle>
								<DialogContent className={styles.calendarDialogContent}>
									<TextField
										className={styles.codePromoField}
										label="Código de promoción"
										margin="normal"
										name="promo_code"
										onChange={event =>
											setPromoCode(event.target.value)
										}
										value={promoCode}
										variant="outlined"
										helperText={"Si no tienes uno déjalo en blanco"}
									/>
									<Button onClick={handleContinue} variant="contained" className={styles.buttonBookingDialogPromo}>
										{
											/^\s*$/.test(promoCode) ? (
												'No tengo'
											) : (
													'Reservar con descuento'
												)
										}
									</Button>
								</DialogContent>
							</Dialog>
							<Dialog onClose={handleCloseDialogCalendar}
								PaperProps={{ style: { margin: '0' } }}
								open={openDialogCalendar} style={{ maxHeigth: '50%' }}>
								<DialogTitle>Selecciona el rango de días a reservar</DialogTitle>
								<TextField
									id="cantSelect"
									select={true}
									//defaultValue={1}
									value={cantSelect}
									onChange={handleChange}
									inputProps={{
										name: "cantidad seleccionada",
										id: "cantSelect"
									}}
									label={(() => {
										switch (payMethod) {
											case "Monthly": return "Cantidad de Meses";
											case "Weekly": return "Cantidad de Semanas";
											case "Daily": return "Cantidad de Dias";
										}
									})()}
									className={styles.textField}
									SelectProps={{
										MenuProps: {
											className: styles.menu,
										},

									}}
									margin="5px"
									variant="outlined"
									borderRadius="50px"
								>
									{
										values[payMethod].map((value, index) => {
											return <MenuItem value={value}>{value}</MenuItem>;
										})}
								</TextField>
								<DialogContent className={styles.calendarDialogContent}>
									<DateRangePicker
										locale={es}
										showDateDisplay={false}
										dragSelectionEnabled={false}
										className={styles.mainWrapper}
										ranges={[dateRange]}
										rangeColors={["#DE892A"]}
										disabledDates={dataDisableDays.ranges}
										minDate={new Date()}
										scroll={{ enabled: true }}
										//monthHeight={0}
										direction="vertical"
										onChange={handleCalendarChange}
										//noBorder={true}
									/>
									<Button onClick={handleBookingCheck} variant="contained" className={styles.buttonBookingDialog}>
										Reservar
						</Button>
									<Button onClick={handleCloseDialogCalendar} variant="contained" className={styles.buttonClose}>
										Cancelar
						</Button>
								</DialogContent>
							</Dialog>


							<Dialog onClose={handleCloseDialogResumen}
								open={openDialogResumen} style={{ maxHeigth: '100%' }}
								PaperProps={{
									style: {
										margin: '0.5rem',
									},
								}}

							>
								<DialogTitle style={{ textAlign: 'center' }}>Resumen de Compra</DialogTitle>
								<DialogContent>
									<List dense={false}>
										<ListItem>
											<p style={{fontWeight:"700", fontSize:"1.2rem", margin:"5px"}}>Valor: </p>
											<NumberFormat value={`${resumenCompra.monto}`} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} prefix={'$ '} />															
										</ListItem>
										<ListItem>
											<p style={{fontWeight:"700", fontSize:"1.2rem", margin:"5px"}}>Correo: </p>
											<ListItemText
												primary={` ${resumenCompra.email}`}
											/>
										</ListItem>
										<ListItem>
											<p style={{fontWeight:"700", fontSize:"1.2rem", margin:"5px"}}>Teléfono: </p>
											<NumberFormat value={`${resumenCompra.telefono}`} displayType={'text'} prefix={'+57 '} />
										</ListItem>
										<ListItem>
											<p style={{fontWeight:"700", fontSize:"1.2rem", margin:"5px"}}>Fecha Inicio: </p>
											<ListItemText
												primary={` ${moment.utc(resumenCompra.startDate).format('LL')}`}
											/>
										</ListItem>
										<ListItem>
											<p style={{fontWeight:"700", fontSize:"1.2rem", margin:"5px"}}>Fecha Finalización: </p>
											<ListItemText
												primary={` ${moment.utc(resumenCompra.endDate).format('LL')}`}
											/>
										</ListItem>
										<ListItem>
											<p style={{fontWeight:"700", fontSize:"1.2rem", margin:"5px"}}>Código promoción: </p>
											<ListItemText
												primary={` ${resumenCompra.promoCode == undefined ? "No aplica" : resumenCompra.promoCode}`}
											/>
										</ListItem>
									</List>
									<DialogContent className={styles.calendarDialogContent}>
										<Button onClick={bottonEnv} variant="contained" className={styles.buttonBookingDialog}>
											Continuar
						  </Button>
										<Button onClick={handleCloseDialogResumen} variant="contained" className={styles.buttonClose}>
											Cancelar
						  </Button>
									</DialogContent>
								</DialogContent>
							</Dialog>

					</Layout>
						) : (
						<Lottie options={defaultOptions} height={300} width={300} style={{
							position: 'absolute',
							left: '50%',
							transform: 'translateX(-50%) translateY(-50%)',
							top: '50%'
						}} />
						)
				}
		</div>
				)
}

			export default Office